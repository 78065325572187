export enum RouteName {
  None = "",
  Root = "Root",
  ContactInformation = "ContactInformation",
  Housing = "Housing",
  Offer = "Offer",
  Billing = "Billing",
  BankDetails = "BankDetails",
  Contract = "Contract",
  ContractSigning = "ContractSigning",
  ContractActivation = "ContractActivation",
  EmailValidationSent = "EmailValidationSent",
  EmailEdit = "EmailEdit",
}

export const SubscriptionFormSteps = {
  [RouteName.ContactInformation]: RouteName.ContactInformation,
  [RouteName.Housing]: RouteName.Housing,
  [RouteName.Offer]: RouteName.Offer,
  [RouteName.Billing]: RouteName.Billing,
  [RouteName.BankDetails]: RouteName.BankDetails,
  [RouteName.Contract]: RouteName.Contract,
} as const;

export interface NavigationState {
  mainHeaderTitle: string;
  currentStep: RouteName | null;
  visitedSteps: VisitedSteps;
}

export interface VisitedSteps {
  [RouteName.ContactInformation]?: boolean;
  [RouteName.Housing]?: boolean;
  [RouteName.Offer]?: boolean;
  [RouteName.Billing]?: boolean;
  [RouteName.BankDetails]?: boolean;
  [RouteName.Contract]?: boolean;
}

export type SubscriptionStep = keyof VisitedSteps;
