import Vue from "vue";
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import mainLayout from "../views/layout/formSubscriptionLayout/formSubscriptionLayoutPage.vue";
import store from "@/store";
import i18n from "@/i18n";
import { RouteName } from "@/store/navigation/type";

Vue.use(VueRouter);

const registerSubscriptionId = (to: Route) => {
  if (to.query.id) {
    store.commit("subscription/setSubscriptionId", to.query.id);
  }
};

const validateEmailToken = (to: Route, next: NavigationGuardNext) => {
  store
    .dispatch("subscription/emailValidation/validateEmail", to.query.token)
    .then((subscriptionId) => {
      store.commit("subscription/setSubscriptionId", subscriptionId);
      next({ name: RouteName.Contract });
    })
    .catch(() => {
      next();
    });
};

const rootRedirection = (to: Route, from: Route, next: NavigationGuardNext) => {
  registerSubscriptionId(to);
  if (to.query.token) {
    validateEmailToken(to, next);
  } else {
    next();
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: { name: RouteName.Offer },
  },
  {
    path: "/",
    component: mainLayout,
    name: RouteName.Root,
    redirect: { name: RouteName.Offer },
    beforeEnter: (to, from, next) => {
      rootRedirection(to, from, next);
    },
    children: [
      {
        path: "contactInformation",
        name: RouteName.ContactInformation,
        component: () =>
          import(
            /* webpackChunkName: "view" */ "../views/contactInformation/contactInformationPage.vue"
          ),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("contactInformation.yourContactDetails")
          );
          next();
        },
      },
      {
        path: "housing",
        name: RouteName.Housing,
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/housing/housingPage.vue"
          ),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("housing.yourAccommodation")
          );
          next();
        },
      },
      {
        path: "offer",
        name: RouteName.Offer,
        component: () =>
          import(/* webpackChunkName: "page" */ "../views/offer/offerPage.vue"),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("offer.yourOffer")
          );
          next();
        },
      },
      {
        path: "billing",
        name: RouteName.Billing,
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/billing/billingPage.vue"
          ),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("billing.yourBilling")
          );
          next();
        },
      },
      {
        path: "bankDetails",
        name: RouteName.BankDetails,
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/bankDetails/bankDetailsPage.vue"
          ),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("bankDetails.yourBankDetails")
          );
          next();
        },
      },
      {
        path: "contract",
        name: RouteName.Contract,
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/contract/contractPage.vue"
          ),
        beforeEnter: (to, from, next) => {
          store.commit(
            "navigation/setMainHeaderTitle",
            i18n.t("contract.yourContract")
          );
          next();
        },
      },
    ],
  },
  {
    path: "/email/emailValidationSent",
    name: RouteName.EmailValidationSent,
    component: () =>
      import(
        /* webpackChunkName: "page" */ "../views/email/emailValidationSentPage.vue"
      ),
    beforeEnter: (to, from, next) => {
      store.commit(
        "navigation/setMainHeaderTitle",
        i18n.t("email.validationSend")
      );
      next();
    },
  },
  {
    path: "/email/edit",
    name: RouteName.EmailEdit,
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/email/editEmailPage.vue"),
    beforeEnter: (to, from, next) => {
      store.commit(
        "navigation/setMainHeaderTitle",
        i18n.t("email.emailModification")
      );
      next();
    },
  },
  {
    path: "/contractSigning",
    name: RouteName.ContractSigning,
    component: () =>
      import(
        /* webpackChunkName: "page" */ "../views/contractSigning/contractSigningPage.vue"
      ),
    beforeEnter: (to, from, next) => {
      store.commit(
        "navigation/setMainHeaderTitle",
        i18n.t("contractSigning.yourContractSigning")
      );
      next();
    },
  },
  {
    path: "/contractActivation",
    name: RouteName.ContractActivation,
    component: () =>
      import(
        /* webpackChunkName: "page" */ "../views/contractActivation/contractActivationPage.vue"
      ),
    beforeEnter: (to, from, next) => {
      let canReachContractActivationPage =
        store.getters[
          "subscription/contractActivation/canReachContractActivationPage"
        ];

      store.commit(
        "navigation/setMainHeaderTitle",
        i18n.t("contractActivation.yourContractActivation")
      );

      const redirectToRoot = () => {
        console.warn("Unauthorized");
        next({ name: RouteName.Root });
      };

      if (canReachContractActivationPage) {
        next();
      } else {
        if (to.query.id) {
          store.commit("subscription/setSubscriptionId", to.query.id);
          store.dispatch("subscription/getSubscription").finally(() => {
            canReachContractActivationPage =
              store.getters[
                "subscription/contractActivation/canReachContractActivationPage"
              ];
            if (canReachContractActivationPage) {
              next();
            } else {
              redirectToRoot();
            }
          });
        } else {
          redirectToRoot();
        }
      }
    },
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  const subscriptionId = store.getters["subscription/getSubscriptionId"];

  switch (to.name) {
    case RouteName.ContactInformation:
    case RouteName.Housing:
    case RouteName.Offer:
    case RouteName.Billing:
    case RouteName.BankDetails:
    case RouteName.Contract:
      store.commit("navigation/setCurrentStep", to.name);
      break;
  }

  switch (from.name) {
    case RouteName.ContactInformation:
    case RouteName.Housing:
    case RouteName.Offer:
    case RouteName.Billing:
    case RouteName.BankDetails:
    case RouteName.Contract:
      store.commit("navigation/setVisitedStep", from.name);
      break;
  }

  switch (to.name) {
    case RouteName.EmailEdit:
    case RouteName.EmailValidationSent:
    case RouteName.ContractSigning:
      if (!subscriptionId) {
        console.warn("Unauthorized");
        next({ name: RouteName.Root });
      } else {
        next();
      }
      break;
    default:
      next();
  }
});

export default router;
